import { Button, Card, Form, Input, notification, Typography } from "antd"
import { useState } from "react"
import API from "../../API"
import useAuth from "../../Hook/Auth"
import axios from "axios";


const Login = ()=>{
    const {login,logout} = useAuth()
    const [loading,setLoading] = useState(false)

    const acceder = async(payload)=>{
        try{
            const { data } = await API.post('api/usuario/login/',payload)
            console.log(data,"esta es la data")
             login(data)
        }catch(error){
            console.log(error,"error")
            // notification.error({ message: error.response.data.message })
        }
    }

    return <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>

        <Card style={{minWidth:400}}>
            <Form  disabled={loading} layout="vertical" onFinish={acceder}>
                <Form.Item label="Nombre de usuario" name="correo" rules={[{required:true,message:"Completa este campo"}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label="Contraseña" name="password" rules={[{required:true,message:"Completa este campo"}]}>
                    <Input.Password/>
                </Form.Item>
                <br></br>
                <Button loading={loading} htmlType="submit" type="primary">Acceder</Button>
            </Form>
        </Card>
    </div>
}


export default Login