import { Breadcrumb, Tabs } from "antd"
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import API from "../../../API";
import EstanqueListado from "../../Estanque/Listado";
import { Formulario } from "../../Formularios/visualizar";

const GranjaDetalle=()=>{
    const {productor_id}=useParams()
    const {id_granja}=useParams()
    const [granja,setGranjas]=useState([])
    const [cargando,setCargando] = useState(false)
    
    const granja_=async ()=>{
        setCargando(true)
        const { data } = await API.get(`api/granja/${id_granja}`)    
        console.log(data,"laksdlaskldaskkaskd")        
        setGranjas(data)
        setCargando(false)
    }

    const items = [
        {
            key: '1',
            label: 'Formularios',
            forceRender:true,
            children:<Formulario id={id_granja}/>,
        },
        {
            key: '2',
            label: 'Estanques',
            children: <EstanqueListado granja={id_granja}/>,
        },
        
    ];
    
    useEffect(()=>{        
        granja_()
    },[])
    return <div style={{padding:32}}>
         <Breadcrumb>
            <Breadcrumb.Item>
                <Link to='/lista-usuarios'>Usuarios</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/productor/${productor_id}`}>Productor</Link>
            </Breadcrumb.Item>            
            <Breadcrumb.Item>
                <Link to={`/granja/${id_granja}`}>Granja {granja.nombre}</Link>
            </Breadcrumb.Item>
         
        </Breadcrumb>        
        <Tabs defaultActiveKey="1" items={items} forceRender={true} ></Tabs>
    </div>
}

export default GranjaDetalle