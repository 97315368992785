import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import API from "../../../../API"
import useAuth from "../../../../Hook/Auth"

const ProductorGranjasListado=()=>{
    const [cargando,setCargando] = useState(false)
    const [docs,setDocs] = useState([])
    const {user}  = useAuth()

    console.log(user)

    const cargar=async ()=>{
            const { data } = await API(`api/usuario/granjas/${user?._id}`)            
            setDocs(data.doc)
    }

    useEffect(()=>{
        cargar()
    },[])

    return <div style={{padding:24}}>
        <Breadcrumb items={[
            {title:<Link to='/'>Inicio</Link>},
            {title:'Mis granjas'}
            ]} />
        <br></br>
        <Table loading={cargando} dataSource={docs} pagination={false}>
            <Table.Column title="Nombres"  render={(d)=><Link to={`/granja/${d._id}/estanques`}>{d.nombre}</Link>} />
            {/* <Table.Column title="Acciones" render={(d)=>(<Space>
                <Button shape="circle" icon={<EditOutlined/>} />
                
            </Space>)} /> */}
        </Table>
    </div>
}

export default ProductorGranjasListado