import { Table } from "antd"

export const Produccion_item = ({ produccion,estanques }) => {
    console.log(produccion, "produccion")
    return <>
        <Table dataSource={produccion} rowKey='_id' pagination={false}>            
            <Table.Column title='Fecha' dataIndex={'fecha_muestreo'}/>
            <Table.Column title='Temperatura' dataIndex={'tiempo_cultivo'}/>
            <Table.Column title='Dias trascurridos' dataIndex={'dias_trascurridos'}/>
            <Table.Column title='Peso promedio' dataIndex={'peso_promedio_peses'}/>
            <Table.Column title='Total Peces' dataIndex={'total_peses_calculado'}/>
            <Table.Column title='biomasa romedio' dataIndex={'biomasa_promedio'}/>
            <Table.Column title='incremento biomasas' dataIndex={'incremento_biomasas'}/>
            <Table.Column title='alimento consumido' dataIndex={'alimento_real_consumido'}/>
        </Table>
    </>

}