// export const ROLES = [
//     {value:1,label:'Admin'},
//     {value:2,label:'Productor'},
//     {value:3,label:'Asistente'},
//     {value:4,label:'Asesor'}
// ]
export const ROL_ADMIN = {value:1,label:'Admin'}
export const ROL_PRODUCTOR = {value:2,label:'Productor'}
export const ROL_ASISTENTE = {value:3,label:'Asistente'}
export const ROL_ASESOR = {value:4,label:'Asesor'}
export const  colombianPeso = Intl.NumberFormat('es-CO');