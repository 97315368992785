import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import API from "../../../../API"
import useAuth from "../../../../Hook/Auth"
import CicloReporte from "../../Ciclo/Reporte"
import ParametrosReporte from "../../Parametros/Reporte"
import { ProduccionReporte } from "../../Produccion/Reporte"

const ProductorEstantesListado=()=>{
    const [cargando,setCargando] = useState(false)
    const [docs,setDocs] = useState([])
    const [doc,setDoc] = useState(null)
    const [doc2,setDoc2] = useState(null)
    const [doc3,setDoc3] = useState(null)
    const [padre,setPadre] = useState({})

    const {user}  = useAuth()
    const {granja_id} = useParams()



    const cargar=async ()=>{
            const { data } = await API(`api/usuario/estanque-granja/${granja_id}`)            
            setDocs(data.doc)
    }

    const cargarPadre=async ()=>{
        const { data } = await API(`api/granja/${granja_id}`)            
        setPadre(data)
    }   

    useEffect(()=>{
        cargar()
        cargarPadre()
    },[])

    return <div style={{padding:24}}>
        <Breadcrumb items={[
            {title:<Link to='/'>Inicio</Link>},
            {title:<Link to='/mis-granjas'>{padre?.nombre}</Link>},
            {title:'Estanques'}
            ]} />
        <br></br>
        <ParametrosReporte estanque={doc} setEstante={setDoc}/>
        <CicloReporte doc={doc2} setDoc={setDoc2} />
        <ProduccionReporte doc={doc3} setDoc={setDoc3}/>
        <Table loading={cargando} dataSource={docs} pagination={false}>
            <Table.Column title="Nombre"  render={(d)=><Link to={`/granja/${d._id}/estantes`}>{d.nombre}</Link>} />
            <Table.Column title="Acciones" render={(d)=>(<Space>
                <Tooltip  title="Parámetros y mediciones">
                    <Button shape="circle" onClick={()=>setDoc(d._id)} icon={<img src='https://cdn-icons-png.flaticon.com/512/4289/4289939.png' width={32} />} />
                </Tooltip>
                <Tooltip title="Ciclos y Gastos">
                    <Button shape="circle" onClick={()=>setDoc2(d._id)} icon={<img src="https://cdn-icons-png.flaticon.com/512/6786/6786333.png" width={32} />} />
                </Tooltip>   
                <Tooltip title="Producción">
                    <Button shape="circle" onClick={()=>setDoc3(d._id)} icon={<img src="https://cdn-icons-png.flaticon.com/512/9874/9874715.png" width={32} />} />
                </Tooltip>               
         
            </Space>)} />
        </Table>
    </div>
}

export default ProductorEstantesListado