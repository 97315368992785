import { DeleteOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import API from "../../../API"
import { ROL_ADMIN, ROL_ASESOR, ROL_PRODUCTOR } from "../../../constantes"
import useAuth from "../../../Hook/Auth"

export const GuardarUsuario = ({ usuario, setUsuario, alGuardar }) => {
    const [cargando, setCargando] = useState(false)
    const [form] = useForm()
    const [asignaciones, setAsignaciones] = useState([])
    const [visible, setVisible] = useState(false)
    const { user } = useAuth()
    const [roles, setRoles] = useState([])
    const [usuario_, setUsuarios_] = useState([])
    const [habilitar, setHabilitar] = useState(false)
    const [habilitarpro, setHabilitarPro] = useState(false)

    function roles_function() {
        if (user.rol == ROL_PRODUCTOR.value) {
            setRoles([
                {
                    id: 3,
                    nombre: "Asistente"
                }
            ]


            )
        } else {
            setRoles([
                {
                    id: 1,
                    nombre: "Admin"
                },
                {
                    id: 2,
                    nombre: "Productor"
                },

                {
                    id: 4,
                    nombre: "Asesor"
                },]

            )
        }

    }


    const guardar = async (payload) => {

        try {

            setCargando(true)


            if (user.rol == ROL_PRODUCTOR.value) {
                console.log(user._id, "user-----")
                if (usuario?._id) {
                    const { data } = await API.patch(`api/usuario/${usuario._id}`, payload)
                    notification.success({ message: "Usuario actualizado correctamente" })
                } else {
                    notification.success({ message: "Usuario Creado correctamente" })
                }

            } if (user.rol == ROL_ADMIN.value) {
                if (usuario?._id) {

                    const { data } = await API.patch(`api/usuario/${usuario._id}`, payload)
                    notification.success({ message: "Usuario actualizado correctamente" })
                } else {
                    const { data } = await API.post('api/usuario/', payload)
                    notification.success({ message: "Usuario Creado correctamente" })

                }

            }
            alGuardar()
            setUsuario(null)

        } catch (error) {
            console.log(error)
            notification.error({ message: error.toString() })
        } finally {
            setCargando(false)
        }

    }

    const listar_productores = async () => {
        try {
            const { data } = await API.get('api/usuario/listar',{params:{rol:ROL_PRODUCTOR.value}})
            setUsuarios_(data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const verBtn = (payload) => {
        if (ROL_ASESOR.value === payload) {
            setHabilitar(true)
            listar_productores()
            setHabilitarPro(false)
            return
        } 

        if(ROL_PRODUCTOR.value === payload) {
            setHabilitarPro(true)
            setHabilitar(false)
            return
        } 

        setHabilitarPro(false)
        setHabilitar(false)
        
    }


    useEffect(() => {
     
        roles_function()

        if (usuario) {
            console.log({usuario})
            form.setFieldsValue(usuario)

            verBtn(usuario.rol)
        } else {
            setHabilitarPro(false)
            setHabilitar(false)
            form.resetFields()
        }
    }, [usuario])



    return <Modal open={usuario} title={usuario?._id ? 'Actualizar Usuario' : 'Guardar Usuario'} closable={!cargando} onCancel={() => setUsuario(null)} okText='Guardar' cancelButtonProps={{ style: { display: 'none' } }} onOk={() => form.submit()}>
        <Form form={form} layout="vertical" onFinish={guardar}>
            <Form.Item label="nombre" name='nombre' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>
            <Form.Item label="apellido" name='apellido' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>
            {!usuario?._id && <Form.Item label="password" name='password' rules={[{ required: true, message: 'Campo requerido' }]}>
                <Input />
            </Form.Item>}
            {!usuario?._id && <Form.Item label="correo" name='correo' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>}
            {!usuario?._id && <Form.Item label="Numero identificación" name='numero_identificacion' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>}
            <Form.Item label="rol" name='rol' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Select options={roles?.map((rol, index) => ({ value: rol.id, label: rol.nombre }))} onChange={verBtn} />
            </Form.Item>

            {habilitarpro && <Form.Item label="Clave formulario" name='clave_formulario' rules={[{ required: true, message: 'Campo requerido' }]} >
                <Input />
            </Form.Item>}
            {
                habilitar ?  <Form.Item name={'usuarios'} label='Productores' >
                <Select
                    mode="multiple"
                    size={25}
                    placeholder="Seleccione los productores disponibles para este asesor"
                    style={{ width: '100%' }}
                    options={usuario_?.map((productor, index) => ({ value: productor._id, label: productor.nombre }))}
                />

            </Form.Item> : null
            }
        
        </Form>
    </Modal>
}
