import { DeleteOutlined, EditOutlined, FolderViewOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Form, Input, notification, Popconfirm, Row, Select, Space, Table } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../../../API"

import CrearProductor from "../CrearProductor"


const ListadoProductor = () => {
    const [doc, setDoc] = useState(null)
    const [asesor, setAsesor] = useState([])
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [cargando, setCargando] = useState(false)
    const onCancel = () => {
        setDoc(null)
        ListaProductor()
    }

    const ListaProductor = async () => {
        try {
            const { data } = await API.get(`api/usuario/listar`, { params: { rol: 2 } })
            console.log(data, '--asssoers')
            setAsesor(data)
        } catch (error) {
            console.error(error)
        }
    }

    const borrar = async (doc) => {
        try {
            const { data } = await API.delete(`api/usuario/${doc?._id}`)
            notification.success({ message: "usuario eliminado correctamente" })
            ListaProductor()
        } catch (error) {
            console.log(error)
        }
    }
    const cargar = async (payload)=>{
        try{
            setCargando(true)
            console.log(payload.rol, payload.usuario)
            const { data } = await API.get(`api/usuario/listar`, { params: { busqueda: payload.usuario, rol: 2 } })
            console.log(data)
            setAsesor(data)
        }catch(error){
            notification.error({message:error.toString()})
        }finally{
            setCargando(false)
        }
    }

    useEffect(() => {
        ListaProductor()
    }, [])

    return <div style={{ padding: 16 }}>
        <Space>
            <Breadcrumb
                items={[
                    {
                        title: 'Productores',
                    }
                ]}
            />
            <Button icon={<PlusOutlined />} shape='circle' type="primary" onClick={() => setDoc({})} />
        </Space>

        <CrearProductor doc={doc} onCancel={onCancel} />
        <Row>
            <Col span={24}>
                <Form layout="vertical" disabled={cargando} form={form} onFinish={cargar}>
                    <Row gutter={10}>
                        <Col xs={24} md={8}>
                            <Form.Item name="usuario" label="usuario">
                                <Input
                                    placeholder="Busque por nombre"
                                    disabled={cargando}
                                    style={{
                                        width: '100%',
                                    }} />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} md={8}>
                            <Form.Item label="&nbsp;">
                                <Space size={8}>
                                    <Button type="primary" style={{ backgroundColor: "#81BD00" }} loading={cargando} htmlType="submit">Filtrar</Button>
                                    {/* <Button type="text" onClick={limpiar} htmlType="button" >Limpiar Filtro</Button> */}
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
        <br /><br />

        <Table
            dataSource={asesor?.data}
            pagination={false}
            bordered={false}
            rowKey='_id'
        >
            <Table.Column title='Nombre' dataIndex={'nombre'} />
            <Table.Column title='Apellido' dataIndex={'apellido'} />
            <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                <Button icon={<EditOutlined />} shape='circle' onClick={() => setDoc(doc)} />
                <Popconfirm title='Desea eliminar este asesor' onConfirm={() => borrar(doc)}><Button icon={<DeleteOutlined />} shape='circle' /></Popconfirm>
                <Button icon={<FolderViewOutlined />} shape='circle' onClick={() => navigate(`/productor/${doc?._id}`)} />
            </Space>} />
        </Table>
    </div >
}

export default ListadoProductor