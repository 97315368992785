import { Col, Divider, Row, Table ,Typography} from 'antd'

import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

export const TablaReporte = ({ key, data, insumos, ciclo, idCiclo }) => {
    const [meses, setMeses] = useState([])
    console.log(ciclo, "este ciclo llega *************************")
    console.log(data, "este la data llega *************************")
    console.log(idCiclo, '.......')
    let grupo_fechas = []

    let cicloTabla = ciclo.filter(e => e?._id === idCiclo)
    console.log(cicloTabla, '-----data table')
    const fechasGrupo = () => {
        grupo_fechas = []
        data.forEach(element => {
            grupo_fechas.push(element?.fecha_insumo)
        });

        let fechaMayor = new Date(Math.max(...grupo_fechas.map(fecha => new Date(fecha))));
        let fechaMenor = new Date(Math.min(...grupo_fechas.map(fecha => new Date(fecha))));
        fechaMayor = dayjs(fechaMayor).format('YYYY-MM-DD')
        fechaMenor = dayjs(fechaMenor).format('YYYY-MM-DD')

        const fechas = []
        let fecha = dayjs(fechaMenor)
        let stopDate = dayjs(fechaMayor)
        while (fecha <= stopDate) {
            fechas.push(fecha.clone())
            fecha = fecha.add(1, 'month')
        }
        const _meses = []
        for (const mes of fechas) {
            _meses.push({ nombre: mes.format('MMM'), numero: mes.month() })
        }
        setMeses(_meses)

    }

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    let data_insumo = Object.entries(groupBy(data, 'insumo')).map(([key, value]) => value);
    let totalInsumo = data.reduce((a, b) => a + parseFloat(b.total), 0)
    let colombianPeso = Intl.NumberFormat('es-CO');
    let ponderada = cicloTabla[0].margen_utilidad_ponderado
    function agruparPorInsumo() {
        const resultado = [];

        for (const elemento of data) {
            const insumo = elemento.insumo;

            if (!resultado[insumo]) {
                resultado[insumo] = [];
            }

            resultado[insumo].push(elemento);
        }

        const resultadoArray = Object.keys(resultado).map((insumo) => ({
            insumo,
            resultado: resultado[insumo]
        }));

        console.log(resultadoArray, '------funcion agrupar insumo')

        return resultadoArray;
    }

    console.log({ data_insumo })



    function final() {
        // Crea un objeto para agrupar los objetos por año y mes
        const groupedData = {};
        const _data = agruparPorInsumo();
        // Itera a través de los objetos y agrupa por año y mes
        _data.forEach(item => {
            item.resultado.forEach(resultado => {
                const yearMonth = resultado.fecha_insumo.substring(0, 7); // Obtiene el año y mes
                if (!groupedData[yearMonth]) {
                    groupedData[yearMonth] = [];
                }
                groupedData[yearMonth].push(item);
            });
        });

        // Convierte el objeto en un array de arrays
        const groupedArray = Object.values(groupedData);
        console.log(groupedArray)
        console.log(groupedArray, 'funcion-----fin')

        console.log(groupBy(groupedArray[0], 'insumos'), '------groupby')


        // agrupar_insumo_mes(groupedArray)
        // Imprime el resultado

    }

    const sumarColumna = (index) => {
        return data.filter(d => dayjs(d.fecha_insumo).month() == meses[index].numero).reduce((a, b) => a + parseFloat(b.total), 0)
    }

    useEffect(() => {
        fechasGrupo()
        final()

    }, [])

    //insumos.filter(j=>j._id === e?.map(e?.insumos)).map(x=>x?.nombre_insumos)

    //dayjs(e[0]?.fecha_insumo).month()=== m.numero ? e[0].total : 0}
    //render={e=>dayjs(e?.fecha_insumo).month()=== m.numero ? sumTotal([e) : 0}
    const costoProduccion = () => colombianPeso.format(totalInsumo / cicloTabla[0].cantidad_peses)
   
    //(<Table.Summary.Cell index={i + 1}>{}</Table.Summary.Cell>)
    // console.log(insumos.filter(j=>j._id === '20cf2147-db5e-4a47-94cf-37cfa16016a5').map(x=>e?.nombre_insumo,'----insumos'))
    return (

        <>
            <Row gutter={10}>
                <Col span={24}>
                    <Typography.Title level={4}>{cicloTabla.map(x=>x.nombre_ciclo)}</Typography.Title>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={data_insumo}
                        rowKey={key} pagination={false}
                        style={{ marginBottom: 16 }}
                        summary={(pageData) => {
                            let totalM = {}
                            return <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                    {
                                        meses.map((e, i) => {
                                            return <Table.Summary.Cell key={i}>{sumarColumna(i)}</Table.Summary.Cell>
                                        })
                                    }
                                    <Table.Summary.Cell key={meses.length + 1}>{totalInsumo}</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        }}
                    >
                        {/* <Table.Column title='Fecha' dataIndex={'fecha_insumo'} /> */}
                        <Table.Column title='Insumos' render={e => insumos.filter(j => j._id === e[0].insumo).map(x => x?.nombre_insumo)} />
                        {meses.map(m => <Table.Column title={m.nombre} render={(text, record, index) => {
                            let tempAr = data_insumo[index].filter(j => dayjs(j.fecha_insumo).month() === m.numero)
                            if (tempAr.length === 1) {
                                return tempAr.map(e => e.total)
                            } else {
                                console.log(tempAr, '----else')
                                return tempAr.reduce((a, b) => {
                                    return a + parseFloat(b.total)
                                }, 0)

                            }
                        }} />)}
                        <Table.Column title='Total' dataIndex={'total'} render={(text, record, index) => {
                            let temp = Object.values(record)
                            console.log(temp, '-----temo')
                            return temp.reduce((a, b) => {
                                return a + parseFloat(b.total)
                            }, 0)
                        }} />

                    </Table>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        bordered={false}
                        dataSource={cicloTabla}
                        rowKey={'_id'}
                    >
                        <Table.Column title='Cantidad de Peces' dataIndex='cantidad_peses' />
                        <Table.Column title='Costo producción' dataIndex={'cantidad_peses'} render={e => costoProduccion()} />
                        <Table.Column title='Peso promedio venta' dataIndex='peso_de_venta' />
                        <Table.Column title='Produccion en Kg' dataIndex='peso_de_venta' render={e => parseFloat(parseFloat(costoProduccion()) / parseFloat(e)).toFixed(2)} />
                        <Table.Column title='Utilidad ponderado' dataIndex='margen_utilidad_ponderado' />
                        <Table.Column title='Precio por Kg' dataIndex='peso_de_venta' render={e => (parseFloat(parseFloat(costoProduccion()) / parseFloat(e)).toFixed(2)) / (1 - ponderada)} />

                    </Table>
                    <Divider></Divider>
                </Col>
            </Row>
        </>
    )
}
