import { Breadcrumb, Button, Card, Space, Table, Tooltip } from "antd"

import { EditOutlined, FolderViewOutlined } from "@ant-design/icons"
import API from "../../API"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"

export const Formularios_item = () => {
    const {id}=useParams()
    const [form,setForm]=useState()
    const navigate = useNavigate();
    const formulario= async()=>{
    try {
        const {data}=await API.get(`api/formularios/${id}`)
       
        setForm(data.docs)
    } catch (error) {
        
    }
    }

    const columns = [
        {
            title: 'nombre',
            dataIndex: 'nombre',
            key: 'name',
        }  ,
        {
            title:'Acciones',
            render:(d)=>(<Space>
                <Tooltip title={'ver formularios de granja'}>
                    <Button shape="circle" icon={<FolderViewOutlined />} onClick={()=>console.log(d)} />
                </Tooltip>
                
            </Space>),
        }  

    ];

    // console.log(granjas[0],'---granjas')
    useEffect(()=>{
        formulario()
    },[])
    return <div style={{padding:32}}>
        <Card>
        <Breadcrumb items={[
            {title:<Link to='/'>Inicio</Link>},
            {title:'Mis formularios'}
            ]} />
        <br></br>
        <Table  dataSource={form} pagination={false}>
            <Table.Column title="Usuario" dataIndex={'usuario'}/>
            <Table.Column title="Acciones" render={(d)=>(<Space>
                <Button shape="circle" onClick={()=>navigate(`/ver-formulario/${d?._id}`)} icon={<FolderViewOutlined />} />
                
            </Space>)} />
        </Table>
    </Card>

    </div>
    
    // navigate(`/ver-formulario/${d?._id}
}