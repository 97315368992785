import { Button, DatePicker, Form, Modal, Space, Table, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import  Dayjs  from "dayjs"
import { useEffect, useState } from "react"
import API from "../../../../API"
import { Estanque_item } from "../../../../Components/Estanque"
import { colombianPeso } from "../../../../constantes"
import useInsumos from "../../../../Hook/Insumos"

const CicloReporte=({doc,setDoc,productor})=>{

    const [docs,setDocs] = useState()
    const [form] = useForm()
    const [insumo,setInsumo]=useState([])
    const [insumos] = useInsumos()

    const cargar=async (payload)=>{
        const {data} = await API('api/estanque/'+doc+'/ciclos',{params:{productor,inicio:payload.fecha[0].toDate(),fin:payload.fecha[1].toDate()}})
        setDocs(data?.docs)
    }

    const sumarGastos=({insumos})=>{
        return '$ '+colombianPeso.format(insumos.reduce((a,b)=>a+parseFloat(b.total),0))
    }

    

    useEffect(()=>{
        form.setFieldValue('fecha',[Dayjs().subtract(1,'month').startOf("month"),Dayjs()])
    },[])

    useEffect(()=>{
        setDocs([])
    },[doc])

    return <Modal open={doc} title="Ciclos y Gastos" footer={[]} onCancel={()=>setDoc(null)} width={960}>
        <br></br>
        <br></br>
        <Form layout="vertical" onFinish={cargar} form={form}>
            <Space size={24}>
            <Form.Item name={'fecha'} label="Rango de fecha">
                <DatePicker.RangePicker/>
            </Form.Item>
            <Button type="primary" htmlType="submit">Generar reporte</Button>
            </Space>
        </Form>
        <br></br>
        <br></br>
        <Table 
        dataSource={docs} 
        pagination={false} 
        rowKey={'_id'}
        scroll={
            { x:500,
             y:400,}
         }
        expandable={{
            expandedRowRender: (record) => (               
                <Table dataSource={record.insumos} pagination={false} >
                    <Table.Column title="fecha insumo" dataIndex={'fecha_insumo'} />
                    <Table.Column title="insumo" dataIndex={'insumo'} render={x=>insumos.filter(i=>x==i._id).map(i=>i.nombre_insumo)} />
                    <Table.Column title="total" dataIndex={'total'} />
                </Table>
            
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        summary={(data)=>{
            const total = colombianPeso.format(data.reduce((a,b)=>a+(b.insumos.reduce((a,b)=>a+parseFloat(b.total),0)),0))
            return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell align="right" index={4}><Typography.Title level={4}>$ {total}</Typography.Title></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>)
        }}>
            <Table.Column title="Fecha inicio" dataIndex={'fecha_inicio'} />
            <Table.Column title="Fecha fin" dataIndex={'fecha_fin'} />
            <Table.Column title="(%) margen de utilidad" dataIndex={'margen_utilidad_ponderado'} />
            <Table.Column title="(Kg) peso de venta" dataIndex={'peso_de_venta'} />
            <Table.Column title="Gastos generados" align="right" render={(d)=>sumarGastos(d)} />
        </Table>
    </Modal>
}

export default CicloReporte