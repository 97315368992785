
import { Breadcrumb, Button, Col, DatePicker, Empty, Form, Input, Row, Select, Table } from "antd";
import Card from "antd/es/card/Card"

import dayjs from "dayjs";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../API";
import { ROL_ADMIN, ROL_PRODUCTOR } from "../../constantes";
import useAuth from "../../Hook/Auth";
import { TablaReporte } from "./Components/TablaReporte";

export const Reporte = () => {
    const { user } = useAuth()
    const [granja, setGranjas] = useState([])
    const [productor, setProductor] = useState([])
    const [estanque, setEstanque] = useState([])
    const [insumos, setInsumos] = useState([])
    const [siclo_, setSiclo_] = useState([])
    const dateFormat = 'YYYY/MM/DD';
    const [ciclos, setCiclos] = useState([])
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm()
    const onChange = (value) => {

        cargar_granjas(value)
    };
    const onChangeEstanque = (value) => {

        cargar_estanque(value)
    };

    const traerDatos = async () => {
        try {
            const { data } = await API('api/usuario/listar')
            if (user.rol == ROL_ADMIN.value) {
                const user_ = data?.data.filter(x => x.rol == ROL_PRODUCTOR.value)
                console.log(user_)
                setProductor(user_)
            } if (user.rol === ROL_PRODUCTOR.value) {
                let array_user=[]
                array_user.push({
                   nombre:user.nombre,
                   _id :user._id
                })
                setProductor(array_user)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const cargar_granjas = async (_id) => {
        try {
            const { data } = await API(`api/usuario/granjas/${_id}`)
            setGranjas(data.doc)

        } catch (error) {
            console.log(error)
        }
    }

    const cargar_estanque = async (id) => {
        try {
            const { data } = await API(`api/usuario/estanque-granja/${id}`)
            console.log(data.doc, "estanque")
            setEstanque(data.doc)
        } catch (error) {
            console.log(error)
        }
    }
    //
    const listarInsumos = async (id) => {
        try {
            const { data } = await API(`api/usuario/insumos/${id}`)
            setInsumos(data?.doc)
        } catch (error) {
            console.log(error, "error -----")
        }

    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const cargarCiclos = async (payload) => {

        try {
            console.log({ payload }, "payload")
            const { data } = await API.get(`api/usuario/reporte/${payload.productor}`, { params: { estanque: payload.estanque, granja: payload.granja } })
            console.log(data, "query")
            if (data.doc.length) {
                const data_mes_con_ciclo = data.ciclo
                setSiclo_(data_mes_con_ciclo)
               
                const data_por_anio = data.doc.filter(x => dayjs(x?.fecha_insumo).toDate().getFullYear() === dayjs(payload.anio).toDate().getFullYear())
                console.log(data_por_anio)
                listarInsumos(payload.productor)
                setCiclos(data_por_anio);
            } else {
                setSiclo_([])
                setCiclos([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        form.resetFields()
        traerDatos()
    }, [])
    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());



    let colombianPeso = Intl.NumberFormat('es-CO');
    const tablaTotal = () => {
        let total = ciclos.length ? ciclos.map(e => e?.insumos.reduce((a, b) => a + parseFloat(b?.total), 0)).reduce((c, d) => c + d, 0) : 0
        console.log(total, '-------mmmm')
        console.log(siclo_.reduce((a, b) => a + parseFloat(b.peso_de_venta), 0), "peso_de_venta")
        let dic = {}
        dic.cantidad_peses = siclo_.length ? siclo_.reduce((a, b) => a + parseFloat(b.cantidad_peses), 0) : 0
        dic.costo_produccion = total > 1 ? colombianPeso.format(parseFloat(total / dic.cantidad_peses).toFixed(4)) : 0
        dic.peso_promedio_venta = siclo_.reduce((a, b) => a + parseFloat(b.peso_de_venta), 0)
        dic.utilidad_ponderada = siclo_.reduce((a, b) => a + parseFloat(b.margen_utilidad_ponderado), 0)
        dic.costo_produccion_kilogramos = dic.costo_produccion ? Math.round(parseFloat(dic.costo_produccion) / parseFloat(dic.peso_promedio_venta)) : 0

        dic.presio_por_kilogramo = (parseFloat(dic.costo_produccion_kilogramos)) / (1 - parseFloat(dic.utilidad_ponderada))
        return [dic]
    }

    console.log(tablaTotal(), '-----total tabla')

    console.log(siclo_, '-----reporte')
    return <div style={{ padding: 32 }}>
        <Row gutter={10}>
            <Col span={24}>
            <Breadcrumb items={[
                    { title: <Link to='/'>Inicio</Link> },
                    {title: "Reporte" },
                   
                ]} />
            </Col>
            <Col span={24}>
                <Card>
                    <Form layout="vertical" form={form} onFinish={cargarCiclos}>

                        <Row gutter={16}>
                            <Col xs={12} md={4}>
                                <Form.Item name="productor" label="Productor" rules={[{ required: true, message: 'ingrese el  Productor', },]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Productor"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={productor.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                                

                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="granja" label="Granja" rules={[{ required: true, message: 'Ingrese la Granja', },]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Granja"
                                        optionFilterProp="children"
                                        onChange={onChangeEstanque}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={granja.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="estanque" label="estanque" rules={[{ required: true, message: 'Ingrese el estanque', },]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione Estanque"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={filterOption}
                                        options={estanque.map(p => ({
                                            value: p?._id,
                                            label: p?.nombre,
                                        }))
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Form.Item name="anio" label="Año" rules={[{ required: true, message: 'ingrese por favor el Año', },]}>
                                    <DatePicker picker="year" bordered={false} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={4}>
                                <Button type="primary" style={{backgroundColor:"#81BD00"}} htmlType="submit" >Filtrar</Button>
                            </Col>
                            <br></br>
                            <br></br>
                            <br></br>
                        </Row>
                    </Form>
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            {
                                ciclos.length > 0 ? (
                                    <>
                                        {ciclos.map((r, i) => <TablaReporte key={i} idCiclo={r.id} data={r.insumos} insumos={insumos} ciclo={siclo_} />)}
                                    </>
                                ) : (
                                    <>
                                        <Empty />
                                    </>
                                )
                            }

                        </Col>
                        <Col>
                            <Table
                                pagination={false}
                                bordered={false}
                                dataSource={tablaTotal()}
                                rowKey={'_id'}
                            >
                                <Table.Column title='Costo producción' dataIndex='costo_produccion' />
                                <Table.Column title='Cantidad de Peces' dataIndex='cantidad_peses' />
                                <Table.Column title='Peso promedio venta' dataIndex='peso_promedio_venta' />
                                <Table.Column title='margen utilidad ponderada' dataIndex='utilidad_ponderada' />
                                <Table.Column title='costo produccion KG' dataIndex='costo_produccion_kilogramos' />
                                <Table.Column title='precio por kilogramo' dataIndex='presio_por_kilogramo' />

                            </Table>

                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>

    </div>
}