import { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate ,useLocation} from "react-router-dom";
import API from "../../API";
export const AuthContext = createContext();
const key_base = process.env.REACT_APP_BASE_LOCAL_STORAGE

const AuthProvider=({children})=>{
    const [token,setToken] = useState(localStorage.getItem("token") || '')
    const [user, setUser] = useState(null);
    const [valid, setValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const {pathname} = useLocation()

    const navigate = useNavigate();

    // function parseJwt (token) {
    //   try{
    //     var base64Url = token.split('.')[1];
    //     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join(''));
    
    //     return JSON.parse(jsonPayload);
    //   }catch(error){
    //     return null
    //   }
    // }

    const login = async(data) => {
        console.log("TOKEN ",data)
        localStorage.setItem("token",data?.token)
        localStorage.setItem("user",JSON.stringify(data))
        setToken(data?.token);
        setUser(data)
        setValid(true)
        navigate('/lista-usuarios',{replace:true});
    };

    const logout = () => {      
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.sessionStorage.removeItem("pathname")
      setUser(null);
      setToken(null);
      navigate("/login", { replace: true });
    };

    const verificationSession = () => {
      try {
          
          const _token = localStorage.getItem("token");
          const _user = localStorage.getItem("user");
  
          if(!_token){
            return navigate("/login", { replace: true });
          }
          
          // navigate("/lista-usuarios", { replace: true });
          setUser(JSON.parse(_user));
          setToken(_token);
          setValid(true)
      } catch (error) {
          console.error('Error en verificar sesión=>', error);
      }finally{
        setLoading(false);
      }
    };
  
  


    // useEffect(()=>{
    //   console.log('token-----',token)
    //   if(token && token!=''){
    //     // validateSession()
    //     setValid(true);
    //   }else{
    //     console.log("No hay token")
    //     setUser(null)
    //     setValid(false)
    //     setLoading(false)
    //   }
    // },[token])
    // console.log(valid,'-------valid')
    
    useEffect(()=>{
      if(valid){        
        let redirect='/lista-usuarios'
        let prev_path=window.sessionStorage.getItem("pathname")
        redirect = prev_path || redirect        
        navigate(redirect,{replace:true});
      }else if(valid===false){
        navigate("/login", { replace: true });
      }
    },[valid])

    useEffect(()=>{
      if(pathname!='/login'){
        window.sessionStorage.setItem("pathname",pathname)
      }
     
    },[pathname])
   
  useEffect(() => {
    verificationSession()
  }, [])

    

    const value = useMemo(
        () => ({
          token,
          user,
          login,
          logout
        }),
        [user,token]
      );

      if(loading){
        return <div>Cargando...</div>
      }

    

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider