import { Table } from "antd"

export const Ciclos_item=({ciclos,granjas,estanques})=>{
    console.log(ciclos,"ciclos")
    return <>
                <Table dataSource={ciclos} rowKey='_id' pagination={false}>
                    
                    <Table.Column title='Fecha_inicio ' dataIndex={'fecha_inicio'} />
                    <Table.Column title='añio creacion' dataIndex={'anio_creacion'} />
                    <Table.Column title='utilidad ponderado' dataIndex={'margen_utilidad_ponderado'} />  
                    <Table.Column title='nombre' dataIndex={'nombre_ciclo'} />   
                     <Table.Column title='peso' dataIndex={'peso_de_venta'} />    
                     <Table.Column title='fecha final' dataIndex={'fecha_final'} />                     
                </Table>
            </>
    
}