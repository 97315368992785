import { LeftCircleOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Divider, Row } from "antd"
import { Tabs } from 'antd';
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../API";
import { Ciclos_item } from "../../Components/Ciclos";
import { Granjas_item } from "../../Components/Granjas";
import { Parametros_item } from "../../Components/Parametros";
import { Produccion_item } from "../../Components/Produccion";


export const DetalleEstanque = () => {
    const [Granjas, setGranjas] = useState([])
    const [Ciclos, setCiclos] = useState([])
    const [Parametros, setParametros] = useState([])
    const [Produccion, setProduccion] = useState([])
    const [Estanque, setEstanque] = useState([])
    const { id, id_estanque } = useParams()
    const navigate = useNavigate()
    // const {id_estanque}=useParams()
    const cargar_granjas = async () => {
        try {
            const { data } = await API(`api/usuario/granjas/${id}`)
            setGranjas(data.doc)
        } catch (error) {
            console.log(error)
        }
    }
    const cargar_estanque = async () => {
        try {
            const { data } = await API(`api/usuario/estanques/${id}`)
            console.log(data.doc, "estanque")
            setEstanque(data.doc)
        } catch (error) {
            console.log(error)
        }
    }
    const cargar_produccion = async () => {
        try {
            const { data } = await API(`api/usuario/produccion/${id}`)
            setProduccion(data.doc.filter(x => x.estanque == id_estanque))
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_parametros = async () => {
        try {
            const { data } = await API(`api/usuario/parametros/${id}`)

            setParametros(data.doc.filter(x => x.estanque == id_estanque))
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_ciclos = async () => {
        try {
            const { data } = await API(`api/usuario/ciclos/${id}`)

            setCiclos(data.doc.filter(x => x.estanque == id_estanque))
        } catch (error) {
            console.log(error)
        }
    }
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: 'Produccion',
            children: <Produccion_item produccion={Produccion} estanques={Estanque} />,
        },
        {
            key: '2',
            label: 'Parametros',
            children: <Parametros_item parametros={Parametros} estanques={Estanque} />,
        },
        {
            key: '3',
            label: 'Ciclos',
            children: <Ciclos_item ciclos={Ciclos} estanques={Estanque} />,
        },
    ];

    useEffect(() => {
        cargar_granjas()
        cargar_produccion()
        cargar_parametros()
        cargar_ciclos()
        cargar_estanque()

    }, [])
    return (<div style={{ padding: 32 }}>
        <Row gutter={10}>
            <Col span={24}>
                <Breadcrumb items={[
                    { title: <Link to='/lista-usuarios'>Inicio</Link> },
                    {title: <Link to={`/productor/${id}`}>granja</Link> },
                    { title: 'Detalle estanque' }
                ]} />
            </Col>
            <br></br>
            <br></br>
            <Col span={24}>
                <Card>
                    {/* <Button onClick={() => navigate(-1)} icon={<LeftCircleOutlined />}>volver</Button> */}
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

                </Card>
            </Col>
        </Row>
    </div>

    )
}