import { Perfil } from "./Components/Perfil"
import ProtectedPage from "./Components/ProtedPage"
import ListadoAsesor from "./Page/Asesores/ListadoAsesor"
import { DetalleEstanque } from "./Page/Detalle_Estanque"
import EstanqueListado from "./Page/Estanque/Listado"
import { Formularios_item } from "./Page/Formularios"
import { Formulario } from "./Page/Formularios/visualizar"
import GranjaDetalle from "./Page/Granja/Detalle"
import GranjaListado from "./Page/Granja/Listado"
import Login from "./Page/Login"
import ProductorDetalle from "./Page/Productor"
import ProductorEstantesListado from "./Page/Productor/Estantes/Listado"
import ProductorGranjasListado from "./Page/Productor/Granjas/Listado"
import ListadoProductor from "./Page/Productores/ListaProductores"
import { Reporte } from "./Page/Reporte"
import ListaUsuarios from "./Page/Usuarios/ListaUsuarios"

const { Router, Routes, Route } = require("react-router-dom")


const App = ()=>{
	return(
		<Routes>
			<Route path="/" element={<ProtectedPage/>}>
				<Route path="/mis-granjas" element={<ProductorGranjasListado/>}/>
				<Route path="/granja/:granja_id/estanques" element={<ProductorEstantesListado/>}/>
				<Route path="/perfil" element={<Perfil/>}/>

				<Route path="/lista-usuarios" element={<ListaUsuarios/>}/>
				<Route path="/asesores" element={<ListadoAsesor/>}/>
				<Route path="/productores" element={<ListadoProductor/>}/>
				<Route path="/productor/:id" element={<ProductorDetalle/>}/>
				<Route path="/productor/:productor_id/granjas" element={<GranjaListado/>}/>
				<Route path="/productor/:productor_id/granja/:id_granja/detalle" element={<GranjaDetalle/>}/>

				<Route path="/detalle-estanque/:id/:id_estanque" element={<DetalleEstanque/>}/>
				<Route path="/reporte/" element={<Reporte/>}/>
				<Route path="/formularios/:id" element={<Formularios_item/>}/>
				<Route path="/ver-formulario/:id" element={<Formulario/>}/>
			</Route>
			
			<Route path="/login" element={<Login/>}/>
		</Routes>
	)
}

export default App