import { DeleteOutlined, EditOutlined, FolderViewOutlined, PlusOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Card, Col, Form, Input, notification, Popconfirm, Row, Select, Space, Table } from "antd"
import Search from "antd/es/input/Search"
import { useEffect, useState } from "react"
import { useNavigate, useNavigation } from "react-router-dom"
import API from "../../../API"
import { ROL_ADMIN, ROL_ASESOR, ROL_PRODUCTOR } from "../../../constantes"
import useAuth from "../../../Hook/Auth"
import { GuardarUsuario } from "../Guardar"



const ListaUsuarios = () => {
    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState(null)
    const [selectUser, setSelectUser] = useState([])
    const [selectRol, setSelectRol] = useState([])
    const { user } = useAuth()
    const [form] = Form.useForm()
    const [filtrado_,setFiltrado]=useState(false)
    const [cargando,setCargando] = useState(false)
    const navigate = useNavigate()


    const borrar = async (doc) => {
        try {
            console.log(doc,"doc-----esedstetetet")
            const { data } = await API.delete(`api/usuario/${doc?._id}`)
            notification.success({ message: "usuario eliminado correctamente" })
            cargar()
        } catch (error) {
            notification.error({ message: error.toString() })
        }
    }

    const cargar = async (payload) => {
        try{
            setCargando(true)
            console.log(payload.rol, payload.usuario)
            const { data } = await API.get(`api/usuario/listar`, { params: { busqueda: payload.usuario, rol: payload.rol } })
            console.log(data)
            setUsuarios(data?.data)
        }catch(error){
            notification.error({message:error.toString()})
        }finally{
            setCargando(false)
        }
        
    }

  
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        cargar({})
        form.resetFields()

    }, [])

  

    const onSearch = (value) => {
        console.log('search:', value);
    };
    
    const limpiar = () => {
        cargar({})
        setFiltrado(false)
        form.resetFields()
    }

    let roles = [{
        label: "Admin",
        value: 1
    },
    {
        label: "Productor",
        value: 2
    },
    {
        label: "Asesor",
        value: 4
    },
    {
        label: "Asistente",
        value: 3
    }
    ]

    useEffect(() => {
        if (usuarios.length) {
            setSelectUser([...usuarios?.map(p => ({ value: p?.nombre, label: p?.nombre }))])
            setSelectRol(roles)
        }
    }, [usuarios])


    return <div style={{ padding: 32 }}>

        <Space>
            <Breadcrumb
                items={[
                    {
                        title: 'Usuarios'
                    }
                ]}
            />
                        <Button type="primary" style={{ backgroundColor: "#81BD00" }} size="small" shape="circle" onClick={() => setUsuario({})} icon={<PlusOutlined />} />
                    
            </Space>
        <br></br>
        <br></br>
        <Card>

         

            <Row gutter={10}>

              

                    
                <br></br>
                <br></br>
                <Col span={24}>
                    <GuardarUsuario usuario={usuario} setUsuario={setUsuario} alGuardar={cargar} />
                    <Col span={24}>
                        <Form layout="vertical" disabled={cargando} form={form} onFinish={cargar}>
                            <Row gutter={10}>
                                <Col xs={24} md={8}>
                                    <Form.Item name="usuario" label="usuario">
                                        <Input
                                            placeholder="Busque por nombre"
                                            disabled={cargando}
                                            style={{
                                                width: '100%',
                                            }}/>                                            
                                    </Form.Item>
                                </Col>
                                {user.rol == ROL_ADMIN.value && <Col xs={24} md={8}>
                                    <Form.Item name="rol" label="Rol">
                                        <Select

                                            showSearch
                                            allowClear
                                            placeholder="Seleccione Rol"
                                            optionFilterProp="children"
                                            disabled={cargando}
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            options={selectRol}
                                            style={{width:'100%'}}
                                        />
                                    </Form.Item>
                                </Col>}
                                <Col xs={24} md={8}>
                                    <Form.Item label="&nbsp;">
                                        <Space size={8}>
                                            <Button type="primary" style={{ backgroundColor: "#81BD00" }} loading={cargando} htmlType="submit" disabled={filtrado_}>Filtrar</Button>
                                            <Button type="text" onClick={limpiar} htmlType="button" >Limpiar Filtro</Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <br></br>
                    <br></br>
                
                    <Table dataSource={usuarios} loading={cargando} rowKey='_id' pagination={false} scroll={{ x:500,y:400,}}>
                    <Table.Column title='Rol' dataIndex={'rol'} render={(r) => r == 1 ? "Admin" : r == 2 ? "Productor" : r == 3 ? "Asistente" : "Asesor"} />

                        <Table.Column title='Nombre' dataIndex={'nombre'} />
                        <Table.Column title='Apellido' dataIndex={'apellido'} />
                      
                        <Table.Column title="Opciones" render={(doc) => <Space size={24}>
                          {user.rol!==ROL_ASESOR.value && <Button icon={<EditOutlined style={{ color: "#81bd00" }} />} shape='circle' onClick={() => setUsuario(doc)}></Button>}
                           {user.rol!==ROL_ASESOR.value &&  <Popconfirm title="Se borrara el usuario permanentemente" onConfirm={() => borrar(doc)}>
                                <Button shape="circle" danger icon={<DeleteOutlined />} ></Button>
                            </Popconfirm>}
                            {doc.rol == ROL_PRODUCTOR.value && <Button icon={<FolderViewOutlined />} shape='circle' onClick={() => navigate(`/productor/${doc?._id}`)}></Button>}
                        </Space>} />
                    </Table>
                </Col>

            </Row>
        </Card>


    </div>
}

export default ListaUsuarios