import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Popconfirm, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import API from "../../../API"
import useAuth from "../../../Hook/Auth"
import CicloReporte from "../../Productor/Ciclo/Reporte"
import ParametrosReporte from "../../Productor/Parametros/Reporte"
import { ProduccionReporte } from "../../Productor/Produccion/Reporte"

const EstanqueListado=({granja})=>{
    const [cargando,setCargando] = useState(false)
    const [docs,setDocs] = useState([])
    const [doc,setDoc] = useState(null)
    const [doc2,setDoc2] = useState(null)
    const [doc3,setDoc3] = useState(null)
    const [productor,seProductor] = useState(null)
    const [padre,setPadre] = useState({})

    const {user}  = useAuth()
    



    const cargar=async ()=>{
            const { data } = await API(`api/usuario/estanque-granja/${granja}`)    
            console.log(data,"estanque")        
            setDocs(data.doc)
    }

    const cargarPadre=async ()=>{
        const { data } = await API(`api/granja/${granja}`)            
        setPadre(data)
    }   
    const seteadordoc=(id,produc)=>{
        setDoc(id)
        seProductor(produc)
    }
    const seteadordoc2=(id,produc)=>{
        setDoc2(id)
        seProductor(produc)
    }
    const seteadordoc3=(id,produc)=>{
        setDoc3(id)
        seProductor(produc)
    }
    useEffect(()=>{
        cargar()
        cargarPadre()
    },[])
   
    return <div style={{padding:24}}>
       
        <ParametrosReporte estanque={doc} productor={productor} setEstante={setDoc}/>
        <CicloReporte doc={doc2} setDoc={setDoc2} productor={productor} />
        <ProduccionReporte doc={doc3} setDoc={setDoc3} productor={productor}/>
        <Table loading={cargando} dataSource={docs} pagination={false}>
            <Table.Column title="Nombre"  dataIndex={'nombre'} />
            <Table.Column title="Acciones" render={(d)=>(<Space>
                <Tooltip  title="Parámetros y mediciones">
                    <Button shape="circle" onClick={()=>seteadordoc(d._id,d.productor)}  icon={<img src='https://cdn-icons-png.flaticon.com/512/4289/4289939.png' width={32} />} />
                </Tooltip>
                <Tooltip title="Ciclos y Gastos">
                    <Button shape="circle" onClick={()=>seteadordoc2(d._id,d.productor)} icon={<img src="https://cdn-icons-png.flaticon.com/512/6786/6786333.png" width={32} />} />
                </Tooltip>   
                <Tooltip title="Producción">
                    <Button shape="circle" onClick={()=>seteadordoc3(d._id,d.productor)} icon={<img src="https://cdn-icons-png.flaticon.com/512/9874/9874715.png" width={32} />} />
                </Tooltip>             
         
            </Space>)} />
        </Table>
    </div>
}

export default EstanqueListado