import { DeleteOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Card, Col, Form, Input, notification, Row, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import API from "../../API"
import { ROL_PRODUCTOR } from "../../constantes"
import useAuth from "../../Hook/Auth"

export const Perfil = () => {
    const { user } = useAuth()
    const [form] = Form.useForm()
    const [passwordVisible, setPasswordVisible] = useState(false);

    const guardar = (payload) => {
        try {                        
            const { abc, correo, jul, message, rol, success, token, _id } = user

            const { data }=API.patch('api/usuario/actualizar/perfil',payload) 
            const newInfo = { abc, apellido: payload.apellido , correo, jul, message, nombre: payload.nombre, rol, success, token, _id }
            localStorage.setItem("user",JSON.stringify(newInfo))
            notification.success({ message: "actualizado correctamente" })
        } catch (error) {
            notification.error({ message: error.response.data.message.toString() })
        }
        
    }

    useEffect(() => {
        form.setFieldsValue(user)
    }, [user])

    return <div style={{ padding: 32, display: "flex", justifyContent: "center" }}>
    <Row gutter={10}>
        <Col span={24}>
        <Breadcrumb items={[
            {title:<Link to='/lista-usuarios'>Inicio</Link>},
            {title:'Perfil'}
            ]} />
        </Col>
        <Col span={24}>
        <Card style={{ width: 500 }}>
            <Row gutter={10}>
                <Col span={24}>

                </Col>
                <Col span={24}>
                    <Form layout="vertical" onFinish={guardar} form={form}>
                        <Form.Item name="nombre" label="Nombre"  >
                            <Input value={user.nombre} />
                        </Form.Item>
                        <Form.Item name="apellido" label="Apellido" >
                            <Input value={user.apellido} />
                        </Form.Item>
                        <Form.Item name="correo" label="Correo"  >
                            <Typography >{user.correo}</Typography>
                        </Form.Item>
                        <Form.Item name="password" label="Cambiar contraseña" tooltip={'Se oculta la contraseña por motivos de seguridad'}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item >
                            <Button type="primary" style={{backgroundColor:"#81BD00"}} htmlType="submit">Actualiza</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Card>
        </Col>
        </Row>
    </div >
}