import { Breadcrumb, Divider,Table } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import API from "../../../API"

const GranjaListado=()=>{
    const {productor_id} = useParams()
    const [productor,setProductor] = useState({})
    const [granjas,setGranjas] = useState([])
    const [cargando,setCargando] = useState(false)

    const cargarProductor = async () => {
        try {
            const { data } = await API(`api/usuario/${productor_id}`)
            setProductor(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar=async ()=>{
        setCargando(true)
        const { data } = await API(`api/usuario/granjas/${productor_id}`)            
        setGranjas(data.doc)
        setCargando(false)
    }

    useEffect(()=>{
        cargarProductor()
        cargar()
    },[])

    return <div style={{ padding: 32, width: "100%", }}>
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to='/productores'>Productores</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/productor/${productor_id}`}>{productor?.nombre}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Granjas</Breadcrumb.Item>
        </Breadcrumb>
        <Divider/>
        <Table loading={cargando} dataSource={granjas} pagination={false}>
            <Table.Column title="Nombres"  render={(d)=><Link to={`/productor/${productor_id}/granja/${d._id}/detalle`}>{d.nombre}</Link>} />
            {/* <Table.Column title="Acciones" render={(d)=>(<Space>
                <Button shape="circle" icon={<EditOutlined/>} />
                
            </Space>)} /> */}
        </Table>
    </div>
}

export default GranjaListado