import { Button, DatePicker, Form, Modal, Space, Table } from "antd"
import { useForm } from "antd/es/form/Form"
import  Dayjs  from "dayjs"
import { useEffect, useState } from "react"
import API from "../../../../API"

const ParametrosReporte=({estanque,setEstante ,productor})=>{

    const [docs,setDocs] = useState()
    const [form] = useForm()

    const cargar=async (payload)=>{
        const {data} = await API('api/estanque/'+estanque+'/parametros',{params:{productor, inicio:payload.fecha[0].toDate(),fin:payload.fecha[1].toDate()}})
        setDocs(data?.docs)
    }

    useEffect(()=>{
        form.setFieldValue('fecha',[Dayjs().subtract(1,'month').startOf("month"),Dayjs()])
    },[])
   
    useEffect(()=>{
        setDocs([])
    },[estanque])

    return <Modal open={estanque} title="Parámetros y Mediciones" footer={[]} onCancel={()=>setEstante(null)}>
        <br></br>
        <br></br>
        <Form layout="vertical" onFinish={cargar} form={form}>
            <Space size={24}>
            <Form.Item name={'fecha'} label="Rango de fecha">
                <DatePicker.RangePicker/>
            </Form.Item>
            <Button type="primary" htmlType="submit">Generar reporte</Button>
            </Space>
        </Form>
        <br></br>
        <br></br>
        <Table dataSource={docs} pagination={false}>
            <Table.Column title="Fecha de muestra" dataIndex={'fecha'} />
            <Table.Column title="PH" dataIndex={'ph'} />
            <Table.Column title="Oxigeno" dataIndex={'oxigeno'} />
            <Table.Column title="Temperatura °" dataIndex={'temperatura'} />
        </Table>
    </Modal>
}

export default ParametrosReporte