import { Table } from "antd"

export const Parametros_item = ({ parametros,  estanques }) => {
    console.log(parametros, "parametros")
    return <>
        <Table dataSource={parametros} rowKey='_id' pagination={false}>           
           
            <Table.Column title='fecha ' dataIndex={'fecha'} />
            <Table.Column title='amonio ' dataIndex={'amonio'} />
            <Table.Column title='oxigeno ' dataIndex={'oxigeno'} />
            <Table.Column title='ph ' dataIndex={'ph'} />
            <Table.Column title='temperatura ' dataIndex={'temperatura'} />
           

        </Table>
    </>

}