import { useEffect, useState } from "react"
import API from "../../API"

const useInsumos=()=>{
    const [docs,setDocs]=useState([])

    const insumos = docs

    const cargar=async ()=>{
        const {data} = await API('api/insumo')
        setDocs(data?.docs)
    }

    useEffect(()=>{
        cargar()
    },[])

    return [insumos]
}

export default useInsumos