import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Row, Col, Button, Checkbox, notification, Breadcrumb, Tooltip, Card ,Select} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';
import { ROL_PRODUCTOR } from '../../../constantes';
import useAuth from '../../../Hook/Auth';
import { useForm } from 'antd/es/form/Form';

const { Dragger } = Upload;

export const Formulario = ({id}) => {
    
    const navigate = useNavigate()
    const firmaRef = useRef()
    const [firma, setFirma] = useState(null)
    const firmaAsesorRef = useRef()
    const [firmaAsesor, setFirmaAsesor] = useState(null)
    const [imageUrl, setImageUrl] = useState([]);
    const [formulario, setFormularios] = useState([]);   
    const [realizarForm, setRealizarForm] = useState(false);
    const [tieneForms, setTieneForms] = useState(false);
    const [form] = useForm()
    const [granjas_p, setGranjas] = useState([])
    const user = useAuth()
  
    const getBase64 = (archivos) => {
        return new Promise((resolve, reject) => {
            console.log({ archivos })
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(archivos[0].originFileObj)
        })
    };

    const cargar = async () => {
        console.log(id,"este llega")
        const {data} = await API.get(`/api/formularios/consulta/${id}`)     
        console.log(data,"formulario")   
        setFormularios(data.docs.docs[0])
       
            // console.log("este formulario se precargo")
            // form.setFieldsValue(data?.docs[0])
        
        // console.log(formulario.length,"data")
    }
  


    const guardar = async (payload) => {
        try {
            console.log("formu")
            

            console.log(formulario, "id formulario")
            if (formulario) {
                console.log(payload,".-.-..-")
                let info = await API.patch(`api/formularios/actualizar/${formulario?._id}`,payload)
                notification.success({ message:'Actualizado correctamente' })

            }else {
                notification.error({ message: 'Para poder editar este formulario debe de esperar que sea llenado anteriormente '})
            }

            
        } catch (error) {
            notification.error({ message: error.toString() })
        } finally {

        }
    }


 

    

    useEffect(() => {
        cargar()
    }, [])

    useEffect(() => {
        if(form && formulario){
            console.log(formulario)
            const {ARCHIVOS,...payload} = formulario
            form.setFieldsValue(payload)
        }
        
    }, [formulario,form])

    return <div style={{padding:32}}>
        <Row gutter={16}>
        
            <br></br>
            <br></br>
            <Col xs={24} md={24} style={{ marginTop: 10, alignContent: 'center' }}>
                <Form layout="vertical" form={form} onFinish={guardar} style={{ border: "solid #75C6FC", padding: 32, borderRadius: 10, backgroundColor: 'white' }}>
                    
                    {/* {!(tieneForms || realizarForm) && <Col xs={24}>
                        <Card>
                            <Form.Item label='Ingrese su Contraseña' name="clave" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Input />
                            </Form.Item>

                            <Button type="primary" style={{ backgroundColor: "#81BD00" }} onClick={validar}>Validar</Button>
                        </Card>
                    </Col>} */}
                     <Row gutter={16}>
                        <Col xs={24} md={4}>
                            {/* <Form.Item name="granja" label="Granja" rules={[{ required: true, message: 'Ingrese la Granja', },]}>
                                <Select
                                    showSearch
                                    placeholder="Seleccione Granja"
                                    optionFilterProp="children"
                                    
                                    onSearch={onSearch}
                                    filterOption={filterOption}
                                    options={granjas_p.map(p => ({
                                        value: p?._id,
                                        label: p?.nombre,
                                    }))
                                    }
                                />
                            </Form.Item> */}
                        </Col>
                        <Col xs={24} md={24}>
                            <h1>Diagnostico versos</h1>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Titulo' name="titulo">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>IDENTIFICACIÓN DEL PISCICULTOR</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Nombre y Apellido' name="nombre_apellido_piscicultor">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Número del documento de identidad' name="numero_documento">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Teléfono' name="telefono_piscicultor">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Dirección de vivienda' name="direccion_de_vivienda">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Asociación o Empresa a la que pertenece' name="empresa_pertenece">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='¿Está interesado en hacer parte de una asociación?' name="interesado_en_asociacion">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>INFORMACIÓN SOCIOECONÓMICA</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Edad' name="edad">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Genero' name="Genero">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'hombre'}>hombre</Radio>
                                    <Radio value={'mujer'}>mujer</Radio>
                                    <Radio value={'otro'}>otro</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Estado civil' name="estado_civil">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'soltero'}>soltero</Radio>
                                    <Radio value={'casado'}>casado</Radio>
                                    <Radio value={'union_libre'}>union libre</Radio>
                                    <Radio value={'viudo'}>viudo </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='¿Cuenta con servicio de internet?' name="cuenta_servio_internet">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label=' ¿Cuál es el tiempo de dedicación a la actividad piscícola?' name="tiempo_dedicacion">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'tiempo_completo'}>Tiempo completo</Radio>
                                    <Radio value={'medio_tiempo'}>Medio tiempo</Radio>

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label=' ¿Cuál es el tiempo de dedicación a la actividad piscícola?' name="tiempo_dedicacion">
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'no_experiencia'}>No tiene experiencia</Radio>
                                    <Radio value={'menos_de_1'}>Menos de 1 año</Radio>
                                    <Radio value={'de_1_a_5'}>1 a 5 años</Radio>
                                    <Radio value={'de_6_a_10'}>6 a 10 años</Radio>
                                    <Radio value={'mas_de_10'}>Más de 10 años</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Formación en acuicultura/piscicultura' name="formacion_acuicultura" >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox value="Profesional">Profesional</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="Tecnólogo">Tecnólogo</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="Técnico">Técnico</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="asistencia_cursos">Asistencia a talleres o cursos cortosD</Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox value="autonomo">Aprendizaje autonomo</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>RESPONSABLE DEL CULTIVO</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Nombre y Apellido' name="nombre_apellido_responsable">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='telefono' name="telefono">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3> DESCRIPCIÓN DEL PREDIO</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='latitud' name="latitud">
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='longitud' name="longitud">
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='altitud' name="altitud">
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='telefono' name="precisión">
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Municipio' name="Municipio">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Corregimiento o Vereda' name="Corregimiento">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Nombre del predio' name="nombre_predio">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Área del predio (Hectáreas)' name="area_predio">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>VOLUMEN DE AGUA</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label=' Volumen total de agua del sistema productivo (m3)' name="volumen_agua">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Volumen de agua en uso (m3)' name="agua_en_uso">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>CARACTERÍSTICAS DEL SISTEMA PRODUCTIVO</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item label='Total de estanques en el sistema productivo piscícola' name="total_estanques_sistema">
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>Características del estanque</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.List name='Características del estanque'>
                                {(insumos, { add, remove }) => (
                                    <div>
                                        {insumos.map((field, i) => (
                                            <div key={i}>
                                                <Row gutter={10}>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'estado_estanque']} rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Radio.Group name="radiogroup" defaultValue={1}>
                                                                <Radio value={'si'}>si</Radio>
                                                                <Radio value={'no'}>no</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'Diametro']} label="Diametro" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type="number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'Capacidad_de_siembra']} label="Capacidad de siembra" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type="number" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Form.Item noStyle>
                                                        <Button shape="circle" onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                                                    </Form.Item>
                                                </Row>
                                            </div>
                                        ))}
                                        <Button onClick={() => add()}>Añadir</Button>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>Forma del estanque</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.List name='Forma del estanque'>
                                {(insumos, { add, remove }) => (
                                    <div>
                                        {insumos.map((field, i) => (
                                            <div key={i}>
                                                <Row gutter={10}>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'Forma']} label="Forma" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Radio.Group name="radiogroup" defaultValue={1}>
                                                                <Radio value={'Circular'}>Circular</Radio>
                                                                <Radio value={'Irregular'}>Irregular</Radio>
                                                                <Radio value={'Rectangular'}>Rectangular</Radio>
                                                                <Radio value={'Cuadradado'}>Cuadradado</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'Material']} label="Material" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Checkbox.Group style={{ width: '100%' }} >
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Tierra">Tierra</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Cemento">Cemento</Checkbox>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        <Checkbox value="Geomembrana">Geomembrana</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Piedra">Piedra</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Plástico">Plástico</Checkbox>
                                                                    </Col>
                                                                </Row>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'sistema_aireacion']} label="Sistema de aireación" rules={[{ required: true, message: 'Campo requerido' }]} >
                                                            <Checkbox.Group style={{ width: '100%' }} >
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Checkbox value="A_chorro">A chorro</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Splash">Splash</Checkbox>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        <Checkbox value="No_tiene">No tiene</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Ventury">Ventury</Checkbox>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Checkbox value="Blower">Blower</Checkbox>
                                                                    </Col>
                                                                </Row>
                                                            </Checkbox.Group>
                                                        </Form.Item>
                                                    </Col>

                                                    <Form.Item noStyle>
                                                        <Button shape="circle" onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                                                    </Form.Item>
                                                </Row>
                                            </div>
                                        ))}
                                        <Button onClick={() => add()}>Añadir</Button>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="sistema_produccion" label="Sistemas de Producción" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Biofloc">Biofloc</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="ras">RAS- Recirculación</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="NAcuaponíao_tiene">Acuaponía</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Tradicional">Tradicional</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Agro-Acuicultura">Agro-Acuicultura</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Otro">Otro (¿Cuál?)</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item name="sistema_produccion_cual" label="cual" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="Tipos_de_producción" label="Tipos de producción" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Alevinaje">Alevinaje</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Engorde">Engorde</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Peces_ornamentales">Peces ornamentales</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="Sistema-de-cultivo" label="Sistema de cultivo" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Monocultivo'}>Monocultivo</Radio>
                                    <Radio value={'Policultivo'}>Policultivo</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="Causas-de-muerte" label="Causas de muerte" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Oxígeno">Oxígeno</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Temperatura">Temperatura</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Nitritos">Nitritos</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            {/* <Checkbox value="Nitritos">Nitritos</Checkbox> */}
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Enfermedades">Enfermedades(Bacterias)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Enfermedades(Hongos)">Enfermedades (Hongos)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Depredadores">Depredadores</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="Causas-de-muerte_cual" label="cual" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>COMERCIALIZACIÓN</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="produccion_aproximada_anual" label="Producción anual aproximada (Toneladas/por año)" >
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="clasificacion_del_acuicultor_segun_productor" label="Clasificación del acuicultor según producción" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="10">Acuicultor de subsistencia (hasta 10 toneladas/año)</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="10-1-22">Pequeño acuicultor (10,1-22 toneladas/año)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="22-1-240">Mediano acuicultor (22,1-240 toneladas/año)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="240">Gran acuicultor (240 toneladas/año en adelante)</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="como_vende_producto" label="¿Cómo vende el producto final?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Eviscerado">Eviscerado</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Descamado">Descamado</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Arrollado">Arrollado</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Entero">Entero</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Filete">Filete</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Salado">Salado</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="servicio_mesa">Servido a la mesa</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Entero</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="como_vende_producto_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="cual_tipo_de_comercializacion" label="¿Cuál es el tipo de comercialización?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Municipal">Municipal</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Departamental">Departamental</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Nacional">Nacional</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Internacional">Internacional</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="vehiculos_para_comercializar" label="¿La empresa tiene vínculos formales para la comercialización del producto?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="siempre">Siempre tiene vínculos comerciales</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="algunas">Algunas veces tiene vínculos comerciales</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="nunca">Nunca ha tenido vínculos comerciales</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="lleva_registros_finacieros" label="Lleva registros financieros de los gastos" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>Caracterización de las especies cultivadas</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.List name='Caracterización de las especies cultivadas'>
                                {(insumos, { add, remove }) => (
                                    <div>
                                        {insumos.map((field, i) => (
                                            <div key={i}>
                                                <Row gutter={10}>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'nombre_pes']} label="Especie cultivada" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'peso_final']} rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Radio.Group name="radiogroup" defaultValue={1}>
                                                                <Radio value={'0-200'}>0-200</Radio>
                                                                <Radio value={'201-400'}>201-400</Radio>
                                                                <Radio value={'401-600'}>401-600</Radio>
                                                                <Radio value={'601-800'}>601-800</Radio>
                                                                <Radio value={'801-1000'}>801-1000</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'meses']} label="Meses de producción" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Radio.Group name="radiogroup" defaultValue={1}>
                                                                <Radio value={'4'}>4</Radio>
                                                                <Radio value={'5'}>5</Radio>
                                                                <Radio value={'6'}>6</Radio>
                                                                <Radio value={'7'}>8</Radio>
                                                                <Radio value={'9'}>9</Radio>
                                                                <Radio value={'10'}>10</Radio>
                                                                <Radio value={'11'}>11</Radio>
                                                                <Radio value={'12'}>12</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'Uso']} label="Uso" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Radio.Group name="radiogroup" defaultValue={1}>
                                                                <Radio value={'Autoconsumo'}>Autoconsumo</Radio>
                                                                <Radio value={'Comercial'}>Comercial</Radio>
                                                                <Radio value={'Controlador-de_sedimento'}>Controlador de sedimento</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'valor_semilla']} label="Valor de la semilla con transporte (Pesos)" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type='number' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'tamaño_compra']} label="Tamaño de la compra (g)" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type='number' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'provedor_semilla']} label="Proveedor de semilla" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Form.Item noStyle>
                                                        <Button shape="circle" onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                                                    </Form.Item>
                                                </Row>
                                            </div>
                                        ))}
                                        <Button onClick={() => add()}>Añadir</Button>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="PROCESO_SACRIFICIO" label="El proceso de sacrificio lo realiza mediante el uso de la técnica de:" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="shock">Shock térmico "Mezcla de agua + hielo + sal"</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="aturdimiento">Aturdimiento por golpe</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="asfixia">Por Asfixia</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="PROCESO_SACRIFICIO_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>NUTRICIÓN ANIMAL</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="tipo_alimentacion_utilizada_sistema" label="¿Qué tipo de alimentación utiliza en su sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Comercial">Comercial</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Natural">Natural</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Complementaria">Complementaria</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="marca_de_concentrado_usa_para_alimentacion" label=" ¿Qué marca de concentrado usa para la alimentación de los peces del cultivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Solla">Solla</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Italcol">Italcol</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Finca">Finca</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Contegral">Contegral</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Ninguno">Ninguno</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>

                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="marca_de_concentrado_usa_para_alimentacion_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="razon_compra_concentrado" label="¿Cuál es la razón por la que escoge la marca de concentrado?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Precio">Precio</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Calidad">Calidad</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Disponibilidad">Disponibilidad</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="razon_compra_concentrado_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="tiene_alianza_comercial " label=" ¿Tiene alianza comercial para la compra del alimento?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="raccion_diaria_alimneto" label="¿Cómo calcula la ración diaria alimenticia que suministra a los peces?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="recomendada">Por tabla recomendada por la marca del concentrado</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="sugerida">Por tabla sugerida por el profesional</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="ajustada">Por tabla ajustada por el productor</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="a_saciedad">A saciedad</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="tiene_cuenta_frecuencia " label="¿Tiene en cuenta la frecuencia de alimentación según la etapa del ciclo productivo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="lleva_registro_consumo" label="¿Lleva registros del consumo de alimentación por ciclo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="tiene_bodega" label="¿Tiene bodega de almacenamiento de alimentos en buenas condiciones?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="evidencia_arpovechamiento" label="¿Evidencia aprovechamiento total del alimento suministrado a los peces?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="suministra_probioticos" label="¿Suministra probióticos o suplementos durante la alimentación?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="realcion_proteico_crecimineto" label="¿Conoce la relación entre las fases de crecimiento y el contenido proteico del alimento que debe suministrar al pez?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="cantidad_proteina_suministrada" label="¿Que porcentajes de proteína suministra a sus peces?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="40-45">40-45% (Iniciación-aguas calidas)</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="32-38">32-38% (Levante-aguas calidas)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="20-30">20-30% (Engorde-aguas calidas)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="48-50">48-50% (Iniciación-Trucha)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="40-45%"> 40-45% (Levante-Trucha)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="40">40% (Engorde-Trucha)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Ninguno">Ninguno</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="realiza_biometrias" label="Realiza biometrías" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="lleva_registros_tecnicos" label="Lleva registros de indicadores técnicos de producción" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}> si ¿cual?'</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="lleva_registros_tecnicos_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>Tasa de conversión alimenticia (TCA)</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.List name='Tasa de conversión alimenticia (TCA)'>
                                {(insumos, { add, remove }) => (
                                    <div>
                                        {insumos.map((field, i) => (
                                            <div key={i}>
                                                <Row gutter={10}>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'tca']} label="TCA" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type="number" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item name={[field.name, 'peso']} label="Peso (g)/semanas" rules={[{ required: true, message: 'Campo requerido' }]}>
                                                            <Input type="number" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Form.Item noStyle>
                                                        <Button shape="circle" onClick={() => remove(field.name)} icon={<DeleteOutlined />} />
                                                    </Form.Item>
                                                </Row>
                                            </div>
                                        ))}
                                        <Button onClick={() => add()}>Añadir</Button>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                        <Col xs={24} md={24}>
                            <h3>CALIDAD DE AGUA E IMPACTO AMBIENTAL</h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="de_que_fuente_proviene" label="¿De qué fuente proviene el agua que utiliza en el sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="pozo-subterraneo">Pozo subterráneo</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="cuerpo">Cuerpo de agua (río, lago, ciénaga, etc)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="agua-lluvia">Agua lluvia</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="otro">Otro (¿Cuál?)</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="de_que_fuente_proviene_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="con_que_frecuencia_medicion" label="¿Con qué frecuencia realiza las mediciones de los parámetros de calidad de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Diaria'}>Diaria</Radio>
                                    <Radio value={'Semanal'}>Semanal</Radio>
                                    <Radio value={'Quincenal'}>Quincenal</Radio>
                                    <Radio value={'no'}>No realiza</Radio>
                                    {/* <Radio value={'Diaria'}>Diaria</Radio> */}
                                    <Radio value={'Otro'}>Otro (¿Cuál?)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="con_que_frecuencia_medicion_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="parametros_mide_cuales" label="¿Qué parámetros de calidad de agua mide?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Nitratos">Nitratos</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Nitritos">Nitritos</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Amonio">Amonio</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="pH">pH</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="oxigeno">Oxígeno disuelto</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Turbidez">Turbidez</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Alcalinidad">Alcalinidad</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Dureza">Dureza</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Cloruro">Cloruro</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Fosfato">Fosfato</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Sólidos_sedimentables">Sólidos sedimentables</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="solidos_suspendidos">Sólidos suspendidos totales</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Carbono">Carbono</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Nitrogeno">Nitrogeno</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="parametros_mide_cuales_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="con_que_frecuencia_realiza_cambio_agua" label="¿Con qué frecuencia realiza recambio de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'Constantemente'}>Constantemente</Radio>
                                    <Radio value={'Diariamente'}>Diariamente</Radio>
                                    <Radio value={'Semanalmente'}>Semanalmente</Radio>
                                    <Radio value={'Mensualmente'}>Mensualmente</Radio>
                                    <Radio value={'no'}>No realiza</Radio>
                                    <Radio value={'Otro'}>Otro (¿Cuál?)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="con_que_frecuencia_realiza_cambio_agua_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="porcentaje_cambio_agua" label="¿Cuál es el porcentaje (%) de recambio del agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'5'}>5</Radio>
                                    <Radio value={'10'}>10</Radio>
                                    <Radio value={'20'}>20</Radio>
                                    <Radio value={'30'}>30</Radio>
                                    <Radio value={'40'}>40</Radio>
                                    <Radio value={'50'}>50</Radio>
                                    <Radio value={'60'}>60</Radio>
                                    <Radio value={'70'}>70</Radio>
                                    <Radio value={'80'}>80</Radio>
                                    <Radio value={'90'}>90</Radio>
                                    <Radio value={'100'}>100</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="que_haces_con_agua_saliente" label="¿Qué hace con el agua que sale del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="riego_huertas">Riego de cultivos agrícolas o huertas</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="vertimiento_natural">Vertimiento en cuerpo de agua natural</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="alcantarillado">Vertimiento al alcantarillado</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Acuaponía">Acuaponía</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="RAS">RAS</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otra">Otra</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="No_reutiliza_agua">No reutiliza el agua</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="que_haces_con_agua_saliente_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="mide_fisicoquimicos_microbiologicos_del_agua" label="¿Mide los parámetros fisicoquímicos y microbiológicos del agua que sale del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'ocasionalmente'}>Ocasionalmente</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="retira_alimnetos_sobrenadante" label="¿Retira el alimento sobrenadante y heces de los estanques?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'ocasionalmente'}>Ocasionalmente</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="realiza_sifoneo_" label=" ¿Realiza sifoneo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_requiere'}>El sistema productivo no lo requiere</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="producto_para_calidad_de_agua" label="¿Utiliza algún producto para mantener o mejorar la calidad del agua del sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Cal">Cal</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Melaza">Melaza</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Microorganismos">Microorganismos biorremediadores (Probióticos)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Sal">Sal</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Vinagre">Vinagre</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Peroxido-hidrogeno">Peroxido de hidrogeno</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Bicarbonato">Bicarbonato</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">Otro</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="No_realiza">No realiza</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="producto_para_calidad_de_agua_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="alguna_especie_auorizada_unap" label="¿Tiene alguna especie no autorizada por la AUNAP?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="Carpa">Carpa</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="Basa-pangasus">Basa o pangasus</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="No tiene">No tiene</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="alguna_especie_auorizada_unap_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="medidas_contra_fugas" label="¿Cuenta con medidas para evitar fugas de especies cultivadas?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="que_hace_con_residuos_pesqueros" label="¿Qué hace con los residuos pesqueros?" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="alimento_animales">Se usan como alimento para animales</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="se_quema">Se quema</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="cielo_abierto">Se disponen a cielo abierto</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Se_vende">Se vende</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Se_entierrao">Se entierra</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">otro ¿cual?</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="que_hace_con_residuos_pesqueros_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="cuenta_permiso_unap" label=" ¿Cuenta actualmente con el permiso de cultivo expedido por la AUNAP?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="cuenta_permiso_concepcion_de_aguas" label="¿Cuenta actualmente con el permiso para concesión de aguas?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                    <Radio value={'no_requiere'}>no requiere</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="cuenta_certificado_establecimiento" label="¿Cuenta con el certificado como establecimiento de Acuicultura Biosegura?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'en_tramite'}>en tramite</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="manejo_gestion_residuos" label="¿Realiza manejo y gestión de los residuos y de vertimientos generados en el sistema productivo piscícola?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="mantenimineto_infraestructura" label="¿Cuenta con un Programa de Mantenimiento de Infraestructura y Equipos?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'_no_equipos'}>No cuenta con programas, pero realiza el mantenimiento pertinente a la infraestructura y a los equipos</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="manejo_plagas" label="¿Cuenta con el programa de manejo de plagas y predadores?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_cuenta'}>No cuenta con programas, pero realiza el manejo de plagas y predadores de manera correcta</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="programa_de_limpieza" label=" ¿Cuenta con un Programa de limpieza y desinfección del sistema productivo?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                    <Radio value={'no_cuenta'}>No cuenta con programas, pero realiza limpieza y desinfección en todo el sistema productivo de manera periódica.</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="reservorio_agua" label="¿Cuenta con reservorio de agua?" rules={[{ required: true, message: 'Campo requerido' }]}>
                                <Radio.Group name="radiogroup" defaultValue={1}>
                                    <Radio value={'si'}>si</Radio>
                                    <Radio value={'no'}>no</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="PROYECCIÓN_DEL_PROYECTO" label="PROYECCIÓN DEL PROYECTO" rules={[{ required: true, message: 'Campo requerido' }]} >
                                <Checkbox.Group style={{ width: '100%' }} >
                                    <Row>
                                        <Col span={10}>
                                            <Checkbox value="escalonada">Realizar producción escalonada</Checkbox>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox value="cantidad_estanque">Aumentar la cantidad de estanques</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="sistemas">Implementar sistemas de aireación para aumentar la producción</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="sistemas_productivos">Implementar nuevos sistemas de producción (Biofloc, acuaponía, RAST, otros)</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Implementar_alimentación">Implementar alimentación alternativa</Checkbox>
                                        </Col>
                                        <Col span={16}>
                                            <Checkbox value="Otro">otro ¿cual?</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item name="PROYECCIÓN_DEL_PROYECTO_cual" label="¿cual?" >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            {/* <Form.Item name="ARCHIVOS" label="ARCHIVOS" >
                                <Dragger {...props}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">AGREGA LOS ARCHIVOS A ENVIAR DENTRO DE ESTA AREA</p>
                                    <p className="ant-upload-hint">
                                        TODOS LOS ARCHIVOS NECESARIOS PARA COMPLETAR LA INFORMACION DE ESTE FORMULARIO AGREGARLOS ACA
                                    </p>
                                </Dragger>
                            </Form.Item> */}
                        </Col>
                        <Col xs={24} md={24}>
                            {formulario?.firma_productor ? <div style={{ border: 'solid', width: 400, height: 200 }}> <img src={formulario[0]?.firma_productor} /></div> : <Form.Item name="firma_productor" label="Firma Productor">
                                {/* <SignatureCanvas
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        style: { "border": "1px solid #000000" }
                                    }}
                                    minWidth={2}
                                    maxWidth={2}
                                    ref={firmaRef}
                                    onEnd={() => (
                                        setFirma(firmaRef.current.getTrimmedCanvas().toDataURL("image/jpg"))
                                    )} /> */}
                            </Form.Item>}

                        </Col>
                        <Col xs={24} md={24}>
                            {/* {formulario[0]?.firma_asesor?<div style={{border:'solid' ,width:400,height:200,marginTop:10}}><img src={formulario[0]?.firma_asesor} /></div> :<Form.Item name="firma_asesor" label="Firma Asesor">
                                <SignatureCanvas
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        style: { "border": "1px solid #000000" }
                                    }}
                                    minWidth={2}
                                    maxWidth={2}
                                    ref={firmaAsesorRef}
                                    onEnd={() => (
                                        setFirmaAsesor(firmaAsesorRef.current.getTrimmedCanvas().toDataURL("image/jpg"))
                                    )} />
                            </Form.Item>}
                             */}
                            <Form.Item name="firma_asesor" label="Firma Asesor">
                                {/* <SignatureCanvas
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        style: { "border": "1px solid #000000" }
                                    }}
                                    minWidth={2}
                                    maxWidth={2}
                                    ref={firmaAsesorRef}
                                    onEnd={() => (
                                        setFirmaAsesor(firmaAsesorRef.current.getTrimmedCanvas().toDataURL("image/jpg"))
                                    )} /> */}
                            </Form.Item>

                        </Col>
                        <Col xs={24} md={24}>
                            {/* {!formulario.length &&<Tooltip title={'Enviar formulario'}>
                                <Button type="primary" style={{ backgroundColor: "#81BD00" }} htmlType="submit" >Enviar</Button>
                            </Tooltip>} */}
                            <Tooltip title={'Enviar formulario'}>
                                <Button type="primary" style={{ backgroundColor: "#81BD00" }} htmlType="submit" >Enviar</Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </div>
}