import { BookOutlined, EnvironmentOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Layout, Menu, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { useState } from "react";
import { Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import { ROLES, ROL_ADMIN, ROL_ASESOR,ROL_PRODUCTOR} from "../../constantes";
import logo from "../../assets/imagenes/logo.png"
import useAuth from "../../Hook/Auth";


const ProtectedPage = () => {
    const { user, logout } = useAuth()
    const [collapsed, setCollapsed] = useState(false);
    const { pathname } = useLocation()
    const [boolTheme, setBoolTheme] = useState(true)

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    if (!user) {
        return <Navigate to="/login" />;
    }

    const role_code = user?.rol

    const items = () => {
        let _items = []
        switch (role_code) {
            case ROL_ADMIN.value:
                _items = [
                    {
                        key: '/lista-usuarios',
                        icon: <UserOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/lista-usuarios">Usuarios</NavLink>
                    },
                    {
                        key: '/productores',
                        icon: <UserOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/productores">Productores</NavLink>
                    },
                    {
                        key: '/asesores',
                        icon: <UserOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/asesores">Asesores</NavLink>
                    },
                    {
                        key: '/reporte',
                        icon: <BookOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/reporte">Reporte</NavLink>
                    }
                ]
                break
            case ROL_ASESOR.value:
                _items=[
                    {
                        key: '/lista-usuarios',
                        icon: <UserOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/lista-usuarios">Usuarios</NavLink>
                    },
                    
                ]
                break
                case ROL_PRODUCTOR.value:
                _items = [
                    {
                        key: '/lista-usuarios',
                        icon: <UserOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/lista-usuarios">Usuarios</NavLink>
                    },
                    {
                        key: '/reporte',
                        icon: <BookOutlined style={{fontSize:18}}/>,
                        label: <NavLink to="/reporte">Reporte</NavLink>
                    }
                    ,
                    {
                        key: '/mis-granjas',
                        icon: <EnvironmentOutlined style={{fontSize:18}}/>,
                        label: <NavLink to={`/mis-granjas`}> Mis Granjas</NavLink>
                    }
                ]
                break

        }
        return _items
    }



    const menus = [
        {
            key: '1',
            label: (
                <NavLink to='/perfil' ><Typography.Text>Perfil</Typography.Text></NavLink>
            )
        },
        {
            key: '2',
            label: (
                <Typography.Text onClick={() => logout()}>Salir</Typography.Text>
            )
        },

    ]

    return <Layout>
        <Sider
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#0259bf',
            }}
            trigger={null} collapsible collapsed={collapsed}
        >
            <div style={{ marginTop: 32, marginBottom: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',color:'whitesmoke' }}>
                {
                    !collapsed ? <><Avatar size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 64,
                        xl: 80,
                        xxl: 100,
                    }} src={logo} /></> : ''
                }
                
            </div>

         


            <Menu style={{ fontSize: 16, border: 'none', backgroundColor: '#0259bf',color:'whitesmoke' }} mode="inline" defaultSelectedKeys={['1']} selectedKeys={[pathname]} items={items()}/>

            <center>
                <Button
                    type="primary"
                    onClick={toggleCollapsed}
                    style={{
                        marginBottom: 16,
                        marginTop:20
                    }}
                >
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
            </center>
        </Sider>

        <Layout
            className="site-layout"
            style={{
                marginLeft: collapsed ? 60 : 200
            }}
        >

            <Content
                style={{
                    overflow: 'initial', minHeight: '100vh'
                }}
            >

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', margin: 10, flexDirection: 'column' }}>


                    <Space >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <Space>
								<Switch
									checked={isDark}
									unCheckedChildren='Claro'
									checkedChildren='Oscuro'
									onChange={(i) => cambioTheme(i)}
									style={{ marginRight: 20 }}
								/>
							</Space> */}
                            
                                
                                    <div >
                                       
                                        <Typography.Text >{user?.nombre} {user?.apellido}</Typography.Text>
                                        <Typography.Text size="small" type='success' italic>{user?.rol === ROL_PRODUCTOR.value ? ROL_PRODUCTOR.label ?user?.rol===ROL_ASESOR.value:ROL_ASESOR.label?user?.rol===ROL_ADMIN.value:ROL_ADMIN.label :''}</Typography.Text>
                                    </div>
                                
                            
                        </div>
                        <Dropdown
							menu={{ items: menus }}
                            
						>
							<Avatar size={64} src={logo} />
						</Dropdown>
                    </Space>

                </div>

                <Outlet />


            </Content>
            {/* <Footer
				style={{
					textAlign: 'center'
				}}
			>
				Ondas 2023
			</Footer> */}
        </Layout>
    </Layout>;

}
export default ProtectedPage