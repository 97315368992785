import { EditOutlined, LeftCircleOutlined, SettingOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Collapse, Descriptions, Divider, Flex, Row, Select, Switch, Table, Typography } from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../API";
import { Ciclos_item } from "../../Components/Ciclos";
import { Estanque_item } from "../../Components/Estanque";
import { Granjas_item } from "../../Components/Granjas";
import { Parametros_item } from "../../Components/Parametros";
import { Produccion_item } from "../../Components/Produccion";



const ProductorDetalle = () => {
    const [expandIconPosition, setExpandIconPosition] = useState('start');
    const [Granjas, setGranjas] = useState([])
    const [Estanque, setEstanque] = useState([])
    const [Ciclos, setCiclos] = useState([])
    const [Parametros, setParametros] = useState([])
    const [Produccion, setProduccion] = useState([])
    const [Productor, setProductor] = useState([])
    const navigate = useNavigate()
    const { id } = useParams()

    const cargar_granjas = async () => {
        try {
            const { data } = await API(`api/usuario/granjas/${id}`)
            setGranjas(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_productor = async () => {
        try {
            const { data } = await API(`api/usuario/${id}`)
            console.log(data.doc, "produkk,kk")
            setProductor(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_estanque = async () => {
        try {
            const { data } = await API(`api/usuario/estanques/${id}`)
            console.log(data.doc, "estanque")
            setEstanque(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_produccion = async () => {
        try {
            const { data } = await API(`api/usuario/produccion/${id}`)
            setProduccion(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_parametros = async () => {
        try {
            const { data } = await API(`api/usuario/parametros/${id}`)

            setParametros(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const cargar_ciclos = async () => {
        try {
            const { data } = await API(`api/usuario/ciclos/${id}`)
            console.log(data.docs, "docs")
            setCiclos(data.doc)
        } catch (error) {
            console.log(error)
        }
    }

    const habilitar = async(checked)=>{
        try {
            const {data} = await API.patch(`api/usuario/${Productor?._id}`,{active:checked})
            cargar_productor()
        } catch (error) {
            console.log(error)
        }
    }

 

 

    const items = [
        {
            key: '1',
            label: 'Granja',
            children: <Granjas_item granjas={Granjas} estanques={Estanque} id={id} />,
        }

    ];

    const _items = [
        { key: '1', label: 'Nombre', children: Productor?.nombre, },
        { key: '2', label: 'Apellido', children: Productor?.apellido, },
        { key: '3', label: 'Correo', children: Productor?.correo, },
        { key: '4', label: 'Clave formulario', children: Productor?.clave_formulario,},
        { key: '5', label: 'Habilitar Productor', children:<>
           <Switch checked={Productor?.active} onChange={habilitar}/>
        </>},
    ]
    
    useEffect(() => {
        // cargar_granjas()
        // cargar_estanque()
        // cargar_ciclos()
        // cargar_parametros()
        // cargar_produccion()
        cargar_productor()
    }, [])

    return (
        <div style={{ padding: 32, width: "100%", }}>
            
                    <Breadcrumb items={[
                        { title: <Link to='/Productores'>Productor</Link> },
                        { title: Productor?.nombre}
                    ]} />
               
                <Divider></Divider>
                <div>
                <Descriptions items={_items} /> 
                </div>
               <Link to={`/productor/${id}/granjas`}>
                <Card>
                        <Typography.Title level={1}>Granjas</Typography.Title>
                        <Typography.Title level={3}>100</Typography.Title>
                    </Card>
               </Link>
               
                   


                      
                        {/* <Divider orientation="left" >Información en la plataforma</Divider>
                        <Collapse
                            // defaultActiveKey={['1']}
                            // onChange={onChange}
                            expandIconPosition={expandIconPosition}
                            items={items}
                            style={{ width: '100%', maxWidth: '100%'}}
                        /> */}
                    
         

        </div>
    );
}

export default ProductorDetalle