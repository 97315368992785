import { Col, Form, Input, Modal, notification, Row, Select } from "antd"
import { useEffect, useState } from "react"
import API from "../../../API"
import { ROL_ASESOR, ROL_PRODUCTOR } from "../../../constantes"


const CrearAsesor = ({ doc, onCancel }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const onOk = () => form.submit()
    const [productor, setProductor] = useState([])

    const guardar = async (payload) => {
        try {
            if (doc?._id) {
                const { data } = await API.patch(`api/usuario/${doc._id}`, payload)
                notification.success({ message: "Usuario actualizado correctamente" })
                onCancel()
            } else {
                const { data } = await API.post('api/usuario/', { ...payload,rol: ROL_ASESOR.value,active:true })
                oncancel()
            }
        } catch (error) {
            console.log(error)
        }
    }

    const listar_productores = async () => {
        try {
            const { data } = await API.get('api/usuario/listar', { params: { rol: ROL_PRODUCTOR.value } })
            setProductor(data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    const rules = { required: true, message: 'Campo requerido' }

    useEffect(() => {
        if (doc) {
            form.setFieldsValue(doc)
        }
    }, [doc])

    useEffect(() => {
        listar_productores()
    }, [])

    return <Modal maskClosable={false} title='Registro' onOk={onOk} okText='Guardar' closable={!loading} open={doc} onCancel={onCancel} cancelButtonProps={{ style: { display: 'none' } }} width={500} style={{ width: 800 }}>
        <Form layout="vertical" onFinish={guardar} form={form} >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name='nombre' label='Nombre' rules={[rules]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='apellido' label='Apellido' rules={[rules]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='correo' label='Correo Electrónico' rules={[rules]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name='password' label='Contraseña' rules={[rules]}>
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={'usuarios'} label='Productores' >
                        <Select
                            mode="multiple"
                            size={25}
                            placeholder="Seleccione los productores disponibles para este asesor"
                            style={{ width: '100%' }}
                            options={productor?.map((productor, index) => ({ value: productor._id, label: productor.nombre }))}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}

export default CrearAsesor