import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL
console.log(BASE_URL)

const API = axios.create({
    baseURL:BASE_URL
})

API.interceptors.request.use((config)=>{
    const key_base = process.env.REACT_APP_BASE_LOCAL_STORAGE
    const token = localStorage.getItem('token')
  
    if(token && token!='' && token!='null'){
        config.headers['authorization']='Bearer '+token
    }

    return config
})

export default API